
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator'
import Header from '@/components/shared/Header.vue'
import { State } from 'vuex-class'

@Component({
  components: {
    Header,
  },
})
export default class SellerHeader extends Vue {
  @State(({ userState }) => userState.userInfo.fullname) fullname!: string
  @State(({ userState }) => userState.userInfo.fullname.charAt(0))
  initial!: string

  petitions!: number = localStorage.getItem('petitions')
  pending_petitions!: number = localStorage.getItem('pending_petitions')

  @Prop() readonly headerText!: string
  @Prop() readonly to?: string
  @Prop() readonly superAdmin?: boolean

  public logout(): void {
    this.$toast.success(`Hasta luego ${this.fullname}`)
    localStorage.clear()
    localStorage.removeItem('jwt')
    this.$router.push('/login')
  }

  goToGlobalReport() {
    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN +
      '/reports/global/event/settlement?' +
      'jwt=' +
      localStorage.getItem('access_token')
  }
}
