
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getAllSellers, putSeller } from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'

@Component({
  components: {
    SellerHeader,
  },
})
export default class ListSellers extends Vue {
  sellers: [] = []
  sellersFiltered: [] = []
  sellersTotalPages = 1
  sellersPage = 1
  filter = ''
  status: string = 'all'

  async getSellers(page?, query?, status?) {
    try {
      const { meta, data } = await getAllSellers(page, query, status, 25)

      // Length de paginador
      this.sellersTotalPages = meta.last_page

      this.sellers = data
      this.sellersFiltered = this.sellers

      // Asigno valor a variable status para que cambie de color el boton (en caso de que haya habido algun cambio)
      this.status = status

      // Aisgno valor a pagina para que muestre la pagina actual en paginador
      this.sellersPage = page
    } catch (error) {
      console.error(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  mounted() { }

  @Watch('sellersPage', { immediate: true, deep: true })
  onSellersPageChange(nextPage: number) {
    this.getSellers(nextPage, this.filter, this.status)
  }

  async setSellerActive(value, seller) {
    const body = {
      user: {
        active: value ? 1 : 0,
      },
    }

    const response: any = await putSeller(seller.id, body)

    const message = value ? 'activado' : 'desactivado'

    if (response.status == 200) {
      Vue.$toast.success(`Usuario ${message} correctamente`)
      seller.active = !seller.active
    } else {
      Vue.$toast.error('Ha ocurrido un error actualizando el usuario')
    }
  }

  createSeller() {
    this.$router.push('/seller/administration/seller-create')
  }
}
